import styles from './styles.module.scss';

import React from 'react';
import {get} from 'lodash/object';
import {isEmpty} from 'lodash/lang';
import {Link, NavLink, useLocation} from 'react-router-dom';
import {Badge} from 'reactstrap';

import {useAuth} from 'contexts/AuthContext';
import {
  COMPANY_TYPE_LEVEL,
  IS_DEFAULT,
  ENABLE_COMPLETED_MIGRATION,
  DOCUMENTATION_LANDING_URL,
} from 'configs';
import startsWith from 'lodash/startsWith';
import classNames from 'classnames';
import {ImageLogo} from 'components/Images';

function PrivateSidebarNavsItem(props) {
  const {nav} = props;
  const {to, href, content, isActive} = nav;

  return (
    <li>
      {href ? (
        <a href={href} target="_blank" rel="noopener noreferrer">
          {content}
        </a>
      ) : (
        <NavLink to={to} isActive={isActive ? () => isActive : null}>
          {content}
        </NavLink>
      )}
    </li>
  );
}

function PrivateSidebarNavs(props) {
  const {value, hr = true} = props;
  const {header, navs} = value;

  if (isEmpty(navs)) return false;

  return (
    <>
      <div className="my-3">
        {!!header && <small>{header}</small>}
        <ul>
          {(navs || []).map((nav, index) => (
            <PrivateSidebarNavsItem key={index} nav={nav} />
          ))}
        </ul>
      </div>
      {hr && <hr />}
    </>
  );
}

function PrivateSidebar() {
  const auth = useAuth();

  const companyType = get(auth, 'account.company.company_type', '');
  const companyLevel = COMPANY_TYPE_LEVEL[companyType];

  const isDefault = IS_DEFAULT[get(auth, 'account.company.is_default', 0)];
  const isAB = companyLevel === COMPANY_TYPE_LEVEL['ab'];
  const isBiggerThanAB = companyLevel >= COMPANY_TYPE_LEVEL['ab'];
  const isCB = companyLevel === COMPANY_TYPE_LEVEL['cb'];
  const isBiggerThanCB = companyLevel >= COMPANY_TYPE_LEVEL['cb'];
  const isCE = companyLevel === COMPANY_TYPE_LEVEL['ce'];
  const isBiggerThanCE = companyLevel >= COMPANY_TYPE_LEVEL['ce'];
  const isMNCB = companyLevel === COMPANY_TYPE_LEVEL['mncb'];

  const {ab_count, excluded_count} = get(
    auth,
    'account.company.accreditation_bodies_exclusion',
    {}
  );
  const hasExcluded = excluded_count > 0;
  const allExcluded = hasExcluded && ab_count === excluded_count;

  const overviews = {
    header: 'Overview',
    navs: [
      {to: '/dashboard', content: 'Dashboard'},
      (isAB || (isCB && !allExcluded) || isMNCB) && {
        to: '/analytics',
        content: (
          <div className="d-flex align-items-center">
            Analytics
            <div style={{lineHeight: 0}}>
              <Badge color="danger" className="ml-2">
                New
              </Badge>
            </div>
          </div>
        ),
      },
    ].filter((v) => v),
  };
  const location = useLocation();
  const isMatch = (path) => startsWith(location.pathname, path);

  const dataListings = {
    header: 'Data Listings',
    navs: [
      (isBiggerThanAB || isMNCB) && {
        to: '/certification-bodies',
        content: 'Certification Bodies',
      },
      (isAB || (isCB && !allExcluded) || isMNCB) && {
        to: !isCB ? '/certified-entities' : '/certified-entities/live',
        content: 'Certified Entities',
        isActive: isMatch('/certified-entities'),
      },
      // will revert back this one once MNCB is ready for data source
      // ((isAB && hasPermission) || isCB || isCE || isMNCB) && {
      //   to: '/certifications',
      //   content: 'Certifications',
      // },
      (isAB || (isCB && !allExcluded) || isCE || isMNCB) && {
        to: isMNCB || isCB ? '/certifications/live' : '/certifications',
        content: 'Certifications',
        isActive: isMatch('/certifications'),
      },
      isDefault &&
        isBiggerThanCB &&
        isAB && {
          to: '/ab-standards',
          content: 'Standards and Schemes',
        },
      isDefault &&
        isBiggerThanCB &&
        isCB && {
          to: '/cb-standards',
          content: (
            <React.Fragment>
              Standards and Schemes
              <Badge color="danger" className="ml-2">
                New
              </Badge>
            </React.Fragment>
          ),
        },
    ].filter((v) => v),
  };

  const dataManagement = {
    header: 'Data Management',
    navs: [
      isAB && {
        to: '/cb-upload',
        content: 'Import Certification Body',
      },
      (isAB || (isCB && !allExcluded) || isMNCB) && {
        to: '/import-management',
        content: (
          <React.Fragment>
            {isAB ? 'Import Certifications' : 'Import'}
            {ENABLE_COMPLETED_MIGRATION && (
              <Badge color="danger" className="ml-2">
                New
              </Badge>
            )}
          </React.Fragment>
        ),
      },
      isCB &&
        hasExcluded && {
          to: '/reports',
          content: (
            <React.Fragment>
              Aggregated Report
              <Badge color="danger" className="ml-2">
                New
              </Badge>
            </React.Fragment>
          ),
        },
      (!isCB || (isCB && !allExcluded)) && {
        to: '/import-settings',
        content: (
          <React.Fragment>
            Import Settings
            <Badge color="danger" className="ml-2">
              New
            </Badge>
          </React.Fragment>
        ),
      },
      // {to: '/export', content: 'Export'}
    ].filter((v) => v),
  };

  const messages = {
    header: 'Messages',
    navs: [{to: '/enquiries', content: 'Enquiries'}],
  };

  const settings = {
    header: 'Settings',
    navs: [
      isDefault &&
        isBiggerThanCB && {
          to: '/branches',
          content: 'Accounts',
        },
      {
        to: '/users',
        content: 'Users',
      },
      isAB && {
        to: '/ab-profile',
        content: 'Accreditation Body Profile',
      },
      isCB && {
        to: '/cb-profile',
        content: 'Certification Body Profile',
      },
      isCE && {
        to: '/ce-profile',
        content: 'Company Profile',
      },
      {to: '/settings', content: 'Settings'},
    ].filter((v) => v),
  };

  const others = {
    navs: [
      // MNCB doesn't have default type account. We should skip checking default.
      (isMNCB || (isDefault && isBiggerThanCB)) && {
        href: DOCUMENTATION_LANDING_URL,
        content: 'User Guide',
      },
      isBiggerThanCE &&
        !isMNCB && {
          to: '/marks',
          content: 'IAFCertSearch Mark',
        },
    ].filter((v) => v),
  };

  return (
    <nav className={styles['private-sidebar']}>
      <div
        className={classNames('border-bottom', styles['private-sidebar--logo'])}
      >
        <Link to="/">
          <ImageLogo size="lg" />
        </Link>
      </div>

      <PrivateSidebarNavs value={overviews} />

      {/* Will remove this conditions once data source in ce and certifications is done */}
      {(isAB || isCB || isMNCB || isCE) && (
        <React.Fragment>
          <PrivateSidebarNavs value={dataListings} />
        </React.Fragment>
      )}

      {((isAB && isDefault) || (isCB && isDefault) || isMNCB) && (
        <React.Fragment>
          <PrivateSidebarNavs value={dataManagement} />
        </React.Fragment>
      )}

      <React.Fragment>
        <PrivateSidebarNavs value={messages} />
      </React.Fragment>

      <PrivateSidebarNavs
        value={settings}
        hr={isMNCB || (isDefault && isBiggerThanCB)}
      />

      <PrivateSidebarNavs value={others} hr={false} />
    </nav>
  );
}

export default PrivateSidebar;
